import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    name: 'maintenance',
    path: '/',
    component: () => import('../views/Maintenance')
  },
  {
    name: '404-not-found',
    path: '/:pathMatch(.*)*',
    component: () => import('../views/404NotFound'),
    meta: {
      title: '404 Not Found',
    }
  },
  {
    name: 'not-found',
    path: '/404-not-found',
    component: () => import('../views/404NotFound'),
    meta: {
      title: '404 Not Found',
    }
  },
  {
    name: 'birthday',
    path: '/campaign/:campaign/:verify?',
    component: () => import('../views/Campaign'),
    meta: {
      title: 'Birthday Newsletter',
      description: 'Melde dich für den Missy Rockz Birthday Newsletter an.'
    }
  },
  {
    name: 'article-notification',
    path: '/article-notification/:status/:hash?',
    component: () => import('../views/article-notification/ArticleNotification'),
    meta: {
      title: 'Article Notification',
      description: 'Du hast dich erfolgreich registriert'
    }
  },
  {
    name: 'newsletter',
    path: '/newsletter/:status/:hash?',
    component: () => import('../views/newsletter/Newsletter'),
    meta: {
      title: 'Newsletter',
      description: 'Du hast dich erfolgreich registriert'
    }
  },
  {
    name: 'voting',
    path: '/voting/:voting',
    component: () => import('../views/votings/Votings'),
    meta: {
      title: 'Article Notification',
      description: 'Du hast dich erfolgreich registriert'
    }
  },
  {
    name: 'campaign.unsubscribe',
    path: '/:channel/unsubscribe/:hash',
    component: () => import('../views/UnsubscribeNewsletter'),
    meta: {
      title: 'Unsubscribe Campaign',
      description: 'Newsletterabmeldung'
    }
  },
  {
    name: 'test',
    path: '/test',
    component: () => import('../views/TestPage'),
    meta: {
      title: 'Article Notification',
      description: 'Du hast dich erfolgreich registriert'
    }
  },
  {
    name: 'impressum',
    path: '/impressum',
    component: () => import('../views/Impressum'),
    meta: {
      title: 'Impressum',
    }
  },
  {
    name: 'privacy',
    path: '/privacy',
    component: () => import('../views/Privacy'),
    meta: {
      title: 'Datenschutz',
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title + ' | EIP ROCKZ'
  }

  if (to.meta.description) {
    document.querySelector('meta[name="description"]').setAttribute("content", to.meta.description)
  }
})

export default router
