import { createApp } from 'vue'
import App from './App.vue'
import './assets/styles/app.css'
import router from './router'
import VueTheMask from 'vue-the-mask'

let token = '1|X0fBZNrDFE4vZHRcT7cChRndoLfSQmju9f7jIgtX'

window.axios = require('axios')
window.axios.defaults.baseURL = 'https://app.eip-rockz.de/api'
window.axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

const app = createApp(App)

app.use(router)

app.use(VueTheMask)

app.mount('#app')
